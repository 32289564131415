.question {
  background: #fefefe;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  padding: 24px;
  border: 1px solid transparent;

  & + .question {
    margin-top: 8px;
  }

  &.highlighted {
    background: #F4F0FF;
    border: 1px solid #935AFD;

    footer .user-info span {
      color: #29292E;
    }

    button {
      &.checked {
        color: #835afd;
  
        svg circle {
          stroke: #835afd;
        }
        
        svg path {
          stroke: #835afd;
        }
      }
    }
  }

  &.ansewered {
    background: #DBDCDD;

    button {
      &.checked {
        color: #835afd;
  
        svg circle {
          stroke: #835afd;
        }
        
        svg path {
          stroke: #835afd;
        }
      }
    }
  }

  p {
    color: #29292e;
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    .user-info {
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      span {
        margin-left: 8px;
        color: #737380;
        font-size: 14px;
      }
    }

    > div {
      display: flex;
      gap: 16px;
    }

    button {
      border: 0;
      background: transparent;
      cursor: pointer;
      transition: filter 0.2s;

      &.like-button {
        display: flex;
        align-items: flex-end;
        color: #737380;
        gap: 8px;

        &.liked {
          color: #835afd;

          svg path {
            stroke: #835afd;
          }
        }
      }

      &:hover {
        filter: brightness(0.7)
      }
    }
  }
} 